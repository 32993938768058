<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text">{{isUpdate?'แก้ไข':'สร้าง'}}ข้อมูลสินค้าลูกค้า</v-card-title>
    <v-card-text class="popup-background text-h6 ">
      <v-row justify="center" >
        <v-col cols="2">
          <div>แพ็กเกจหลักปัจจุบัน</div>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="form.product_id"
            name="product_id"
            :items="list.product"
            return-object
            item-value="product_id"
            item-text="product_id"
            @change="(val) => {handleProduct(val)}"
            outlined dense background-color="white"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row justify="center" >
        <v-col cols="2">
          <div>หมวดหมู่</div>
        </v-col>
        <v-col cols="3">
          <v-select
            outlined dense background-color="white" 
            hide-details
            disabled
            v-model="form.category"
            :items="list.category"
            item-value="value"
            item-text="name"
            name="category"
          ></v-select>
        </v-col>
      </v-row>

      <v-row justify="center" >
        <v-col cols="2">
          <div>แบบ</div>
        </v-col>
        <v-col cols="3">
          <v-select
            outlined dense background-color="white" 
            hide-details
            disabled
            v-model="form.product_type"
            :items="list.product_type"
            item-value="value"
            item-text="name"
            name="product_type"
          ></v-select>
        </v-col>
      </v-row>

      <v-row justify="center" >
        <v-col cols="2">
          <div>วันที่เริ่มต้น</div>
        </v-col>
        <v-col cols="3">
          <DatePicker
            label="วันเริ่มต้น"
            :value="form.start_date"
            @onChange="(val) => (form.start_date = val)"
            hideDetails
            outlined 
            dense
          />
        </v-col>
      </v-row>

      <v-row justify="center" >
        <v-col cols="2">
          <div>วันที่สิ้นสุด</div>
        </v-col>
        <v-col cols="3">
          <DatePicker
            label="วันสิ้นสุด"
            :value="form.end_date"
            @onChange="(val) => (form.end_date = val)"
            hideDetails
            outlined 
            dense
          />
        </v-col>
      </v-row>

      <v-row justify="center" >
        <v-col cols="2">
          <div>Max Bill</div>
        </v-col>
        <v-col cols="3">
          <v-text-field 
            outlined dense background-color="white" 
            hide-details
            disabled
            v-model="form.bills"
            name="bills"
          />
        </v-col>
      </v-row>

      <v-row justify="center" >
        <v-col cols="2">
          <div>บิลที่ใช้ไปเดือนนี้</div>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined dense background-color="white" 
            hide-details
            disabled
            v-model="form.bill_usage_count"
            name="bill_usage_count"
          />
        </v-col>
      </v-row>
      

    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer/>
      <v-btn 
        outlined class="px-5 mr-10" 
        color="grey" 
        @click="close()"
      >
        ปิด
      </v-btn>
      <v-btn 
        class="px-10 mr-15" 
        color="primary" 
        @click="save()" 
        :loading="loading" 
        :disabled="loading"
      >
        บันทึกข้อมูล
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import * as moment from "moment";

export default {
  components: {
    DatePicker,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    main_package: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id: function (newVal, oldVal) {
      this.getData(newVal);
    },
  },
  computed: {
    isUpdate() {
      return this.id;
    }
  },
  data () {
    return {
      loading: false,
      form:{
        product_id: "",
        name: "",
        category: 0,
        product_type: 0,
        start_date: moment().set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss"),
        end_date: moment().add(1, "months").set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss"),
        is_unlimit: false,
        bills: 0,
        bill_usage_count: 0,
      },
      list:{
        category: [
          { value: 0, name: "หลัก" },
          { value: 1, name: "เสริม" },
        ],
        product_type: [
          { value: 0, name: "ครั้งเดียว" },
          { value: 1, name: "รายเดือน" },
          { value: 2, name: "รายปี" },
        ],
        product:[],
      }
    }
  },
  created () {
    this.getProduct()
    if (this.isUpdate) this.getData(this.id);
  },
  methods: {
    save() {
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(async (result) => {
        if (result.isConfirmed) {
          this.form = {logs_id: this.id, main_package: this.main_package, ...this.form }
          let body = {
            token: this.$jwt.sign(this.form, this.$privateKey, {noTimestamp: true})
          }
          console.log(this.form)
          await this.$axios.post(`${this.$baseUrl}/backend/customer/edit_package`, body)
            .then(async (res) => {
              this.$alertSuccess({ title : res.status });
            })
            .catch((err) => {
              this.$alertServerError({ title : err.error_message });
            })
          this.$emit("onSave");
        }
        this.loading = false;
      });
    },
    close() {
      this.$emit('onClose');
    },
    async getData(id = ''){
      this.loading = true;
      if(id){
        let body = {
          token: this.$jwt.sign({ logs_id: id },this.$privateKey,{ noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/backend/customer/get_package_by_id`, body)
          .then(async (res) => {
            this.form =  { ...res.result };
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        this.form = {
          product_id: "",
          name: "",
          category: 0,
          product_type: 0,
          start_date: moment().set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss"),
          end_date: moment().add(1, "months").set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss"),
          is_unlimit: false,
          bills: 0,
          bill_usage_count: 0,
        }
      }
      this.loading = false;
    },
    async getProduct() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/backend/product/get_product_list`, body)
        .then(res=>{
          this.list.product = res.result;
        })
        .catch((err) => {
          console.log('err',err);
          this.$alertServerError({ title : err.error_message });
        })
    },
    async handleProduct(item){
      let body = {
        token: this.$jwt.sign({ _id: item._id }, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/backend/product/get_by_id`, body)
        .then(res=>{
          this.form.product_id = res.result.product_id;
          this.form.name = res.result.name;
          this.form.category = res.result.category;
          this.form.product_type = res.result.product_type;
          this.form.bills = res.result.bills;
          this.form.is_unlimit = res.result.is_unlimit;
        })
        .catch((err) => {
          console.log('err',err);
          this.$alertServerError({ title : err.error_message });
        })
      // this.form.category = 
      // this.form.product_type = 
    },
  },
}
</script>
